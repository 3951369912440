<template>
    <card-component title="Scheme sync results"
                    icon="check"
                    :is-scrollable="false"
                    style="min-width: 350px">

        <div class="media-list" >


            <div  class="media" v-for="pack in this.package_updates" >

                <div class="media-content">
                    <div class="content">

                      <nav class="level is-mobile mb-0">
                        <div class="level-left">
                          <div class="level-item">
                            <div class="text has-text-weight-bold">
                              {{pack.package}}
                            </div>
                          </div>

                        </div>
                        <div class="level-right">
                          <b-tag v-if="pack.status === 'success'" type="is-success">Synced</b-tag>
                          <b-tag v-else type="is-danger">Unreachable</b-tag>

                        </div>
                      </nav>

                      <span>
                      </span>
                      <div class="pt-2" v-for="update in pack.partition_updates">
                        <nav class="level is-mobile">
                          <div class="level-left">
                            <div class="level-item">
                              <div>
                                <div class="text has-text-weight-medium">
                                  {{ update.name }}
                                </div>
                                <div>
                                  <small class="text has-text-grey">{{ update.msg }}</small>
                                </div>

                                <div v-if="update.exception">
                                  <b-tag type="is-warning">
                                    <small>There's an exception running on this partition!</small>
                                  </b-tag>

                                </div>

                              </div>
                            </div>

                          </div>
                          <div class="level-right">
                            <b-tag v-if="update.status === 'valid'" type="is-success is-light">Valid</b-tag>
                            <b-tag v-else-if="update.status === 'unreachable'" type="is-danger is-light">Unreachable</b-tag>
                            <b-tag v-else type="is-warning is-light">Warning</b-tag>


                          </div>
                        </nav>
                      </div>



                    </div>
                </div>
            </div>
        </div>
        <hr>
        <p class="text is-size-7">
            If a package was not reachable on the moment of the scheme sync, <br> you may need to re-sync once it is back online.
        </p>
        <hr>
        <b-field horizontal >
            <b-field grouped position="is-right">
                <div class="control">
                    <b-button type="is-primary is-outlined" @click="this.$parent.close">Close</b-button>
                </div>
            </b-field>

        </b-field>



    </card-component>
</template>

<script>
    import CardComponent from "../../CardComponent";
    export default {
        name: "SchemeSyncStatusComponent",
        components: {CardComponent},
        props: ['package_updates']
    }
</script>

<style scoped>

</style>