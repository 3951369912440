/* alarm.store.js */


const state = {
  schemes: [],
  exceptions: [],
  partitions: [],

}

// Getter functions
const getters = {
  schemesForProject: (state) => (id) => {
    return state.schemes.filter(scheme => scheme.project.id === id);
  },

  availablePartitions: (state) => (scheme_id) => {
    return state.partitions.filter(part => part.armingscheme_id === null || part.armingscheme_id === scheme_id);
  },

  allPartitions: (state) => {
    return state.partitions;
  }
}

// Actions
const actions = {
  fetchAlarmDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/alarms/${payload.alarm_id}?${payload.params}`)
        .then((response) => {
          //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  armProject({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/arm`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  disarmProject({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/disarm`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  armPartition({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/alarms/${payload.alarm_id}/partitions/${payload.partition_id}/arm`)
        .then((response) => {
          //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
          resolve(response)
        })
        .catch((error) => {

          reject(error)
        })
    })
  },
  disarmPartition({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/alarms/${payload.alarm_id}/partitions/${payload.partition_id}/disarm`)
        .then((response) => {
          //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateOutput({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/outputs/${payload.output_id}/activate`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deactivateOutput({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/outputs/${payload.output_id}/deactivate`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateOutput({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/outputs/${payload.output_id}`, payload.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSensor({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/sensors/${payload.sensor_id}`, payload.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSchemes({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/projects/${payload.project_id}/armingschemes?${payload.params}`)
        .then((response) => {
          commit("SET_SCHEMES", response.data.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createScheme({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/api/projects/${payload.id}/armingschemes`, payload.data)
        .then((response) => {
          commit("CREATE_SCHEME", {data: response.data.data, project_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateScheme({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.put(`/api/projects/${payload.project_id}/armingschemes/${payload.scheme_id}`, payload.data)
        .then((response) => {
          //commit("CREATE_SCHEME", {data: response.data.data, alarm_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSchemeNameOnly({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.put(`/api/projects/${payload.project_id}/armingschemes/${payload.scheme_id}`, {
        name: payload.name,
        nosync: true
      })
        .then((response) => {
          //commit("CREATE_SCHEME", {data: response.data.data, alarm_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  linkSchema({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/partitions/${payload.partition_id}/link`, payload.data)
        .then((response) => {
          //commit("CREATE_SCHEME", {data: response.data.data, alarm_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteScheme({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/api/projects/${payload.project_id}/armingschemes/${payload.scheme_id}`)
        .then((response) => {
          commit("DELETE_SCHEME", payload.scheme_id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  syncSchemes({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/projects/${payload.project_id}/armingschemes?${payload.params}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectPartitions({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/projects/${payload.project_id}/partitions`)
        .then((response) => {
          commit("SET_PARTITIONS", response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchExceptions({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/projects/${payload.project_id}/armingscheme_exceptions`)
        .then((response) => {
          //commit("SET_EXCEPTIONS", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchExceptionDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`/api/projects/${payload.project_id}/armingscheme_exceptions/${payload.id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateException({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/projects/${payload.project_id}/armingscheme_exceptions/${payload.id}`, payload.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createException({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`/api/projects/${payload.project_id}/armingscheme_exceptions`, payload.data)
        .then((response) => {
          //commit("CREATE_SCHEME", {data: response.data.data, alarm_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteException({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(`/api/projects/${payload.project_id}/armingscheme_exceptions/${payload.exception_id}`)
        .then((response) => {
          //commit("CREATE_SCHEME", {data: response.data.data, alarm_id: payload.id})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  armAibox({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/aiboxes/${payload.aibox_id}/arm`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  disarmAibox({commit}, payload) {
    return new Promise((resolve, reject) => {
      this.$axios.patch(`/api/aiboxes/${payload.aibox_id}/disarm`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// Mutations
const mutations = {
  SET_SCHEMES(state, payload) {
    state.schemes = payload
  },
  SET_EXCEPTIONS(state, payload) {
    state.exceptions = payload
  },
  SET_PARTITIONS(state, payload) {
    state.partitions = payload
  },
  CREATE_SCHEME(state, payload) {
    state.schemes.push(payload.data)
  },
  DELETE_SCHEME(state, id) {
    let index = state.schemes.findIndex(scheme => scheme.id === id)
    state.schemes.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
