<template>
    <card-component title="Scheme change results"
                    icon="check"
                    :is-scrollable="false"
                    style="min-width: 350px">

        <div class="media-list">

            <div  class="media" v-for="update in this.partition_updates">

                <div class="media-content">
                    <div class="content">
                        <nav class="level is-mobile">
                            <div class="level-left">
                                <div class="level-item">
                                    <div>
                                        <div v-if="update.schema_name" class="text has-text-weight-medium">{{ update.schema_name }}
                                            <small class="has-text-grey"> | {{ update.action }}</small>
                                        </div>
                                        <div v-else class="text has-text-weight-medium">{{ update.partition_name }}
                                            <small class="has-text-grey"> | {{ update.action }}</small>
                                        </div>

                                        <div class="text has-text-weight-light is-size-7">{{ update.partition_package}}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="level-right">
                                <b-tag v-if="update.status === 'ok'" type="is-success is-light">Succesful</b-tag>
                              <b-tag v-else-if="update.status === 'unreachable'" type="is-danger is-light">Unreachable</b-tag>

                              <b-tag v-else type="is-danger is-light">Failed</b-tag>


                            </div>
                        </nav>


                    </div>
                    <nav class="level is-mobile">
                        <div class="level-right">
                        </div>

                    </nav>
                </div>
            </div>

          <div v-if="this.partition_updates.length === 0" class="media">
            <div class="media-content">
              <div class="content">
                <b-icon type="is-success" size="is-small" icon="check-circle"></b-icon>
                <span>
                    No partitions changes were made for this schema.
                  </span>
              </div>
            </div>
          </div>


        </div>
        <hr>
        <p class="text is-size-7">
            If a package was not reachable on the moment of the scheme change, <br> you need to re-apply the scheme on that package once it is back online.
        </p>
        <hr>
        <b-field horizontal >
            <b-field grouped position="is-right">
                <div class="control">
                    <b-button type="is-primary is-outlined" @click="this.$parent.close">Close</b-button>
                </div>
            </b-field>

        </b-field>



    </card-component>
</template>

<script>
    import CardComponent from "../../CardComponent";
    export default {
        name: "SchemeUpdateStatusComponent",
        components: {CardComponent},
        props: ['partition_updates']
    }
</script>

<style scoped>

</style>